<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Section' : 'Create Section'" />
        <div>
          <md-button :to="{ name: 'Advert Sections' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateSection">
          <md-field :class="getValidationClass('title')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Section title is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('amount')">
            <label>Amount</label>
            <md-input v-model="form.amount" type="number" required></md-input>
            <span class="md-error" v-if="!$v.form.amount.required"
              >Section amount is required</span
            >
          </md-field>
          <div style="width: 100%">
            <md-checkbox v-model="form.visibility_status"
              >Visibility
            </md-checkbox>
          </div>

          <mdc-button :loading="loading">{{
            id ? "Update Section" : "Create Section"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("advert");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      title: "",
    },
    loading: false,
  }),
  validations: {
    form: {
      title: {
        required,
      },
      amount: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createSection", "updateSection", "getSection"]),
    async validateSection() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.visibility_status = data.visibility_status ? "live" : "draft";

        try {
          this.loading = true;
          let call = this.id
            ? this.updateSection(data)
            : this.createSection(data);
          let msg = this.id ? "Section Updated" : "Section Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Advert Sections" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getSectionData() {
      if (this.id) {
        this.fetching = true;
        const { data } = await this.getSection(this.id);
        this.form = {
          id: data.id,
          title: data.title,
          visibility_status: data.visibility_status == "live" ? true : false,
          amount: data.amount,
        };
        this.fetching = false;
        this.metadata = res;
      }
    },
  },
  mounted() {
    this.getSectionData();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
